import React from "react";
import { HeaderFile, HomePage } from "../modules";

const Layout = () => {
  return (
    <div>
      <div style={{ padding: "30px 70px" }}>
        <HeaderFile />
      </div>

      <div style={{ padding: "0px 30px" }}>
        <HomePage />
      </div>

      <div
        className="coming-soon"
        style={{
          background: "linear-gradient(119.52deg, #223409 1.82%, #375112 99.39%)",
        }}
      >
        <h1>Coming Soon!!</h1>
      </div>
    </div>
  );
};

export default Layout;
