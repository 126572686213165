import React from "react";
import styled from "styled-components";
import backgroundImg from "../../assets/homeBackground.png";
import WhyAKInvestment from "./WhyAKInvestment";
import greenBackgroundImg from "../../assets/greenBackground.png";
import { QuotationIcon } from "../../utils/SVG";
import BackgroundImg from "../../assets/background.png";
import { Rate } from "antd";

const HomePage = () => {
  return (
    <Container>
      <BackgroundImage src={backgroundImg} alt="Background" />

      <TextAboveBackground>
        <TextContent>
          Life More <br /> Relaxed with Smart <br />
          <span className="insurance">Insurance</span>
        </TextContent>
        <SubText>
          Explore our tailored insurance solutions and experience the peace of
          mind that comes with knowing you’re covered.
        </SubText>

        <button className="getStarted">Get Started</button>

        <InfoContainer>
          <InfoBox>
            <p className="heading">250,000 Beneficiaries</p>
            <p className="description">Lorem ipsum dolor sit amet dolor</p>
          </InfoBox>

          <InfoBox2>
            <p className="heading">
              4.8 Rated Company{" "}
              <Rate defaultValue={5} disabled style={{ fontSize: 11 }} />
            </p>
            <p className="description">Lorem ipsum dolor sit amet dolor</p>
          </InfoBox2>
        </InfoContainer>
      </TextAboveBackground>

      <AboveBackgroundImage src={BackgroundImg} alt="Green Background" />

      <TestimonialSection>
        <TestimonialImage src={greenBackgroundImg} alt="Green Background" />
        <TestimonialText>
          <IconWrapper>
            <QuotationIcon />
          </IconWrapper>
          I have an amazing experience with Insurya. They have helped me about
          my deposit very well. Highly recommend.
          <p className="userName">Ayush</p>
        </TestimonialText>
      </TestimonialSection>

      <WhyAKInvestment />
    </Container>
  );
};

export { HomePage };

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
`;

const TextAboveBackground = styled.div`
  position: absolute;
  top: 10%;
  left: 28%;
  transform: translateX(-50%);
  text-align: start;
  color: #334e25;

  .getStarted {
    width: fit-content;
    height: 45px;
    padding: 14px 22px;
    border-radius: 67px;
    opacity: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(89, 112, 58, 0.3);
    background: linear-gradient(95.36deg, #688540 -7.65%, #95ba62 100.21%);
    color: #fff;
    border: none;
    cursor: pointer;
    margin: 30px 0px;
    :hover {
      box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.16);
    }
  }
`;

const TextContent = styled.p`
  color: #334e25;
  margin: 0;
  font-size: 50px;
  font-weight: 400;
  line-height: 60px;
  font-family: "Raleway, Source Sans 3", sans-serif;

  .insurance {
    font-weight: 700;
  }
`;

const SubText = styled.p`
  color: #334e25;
  font-size: 16px;
  font-weight: 400;
  max-width: 430px;
  margin-top: 20px;
`;

const AboveBackgroundImage = styled.img`
  position: absolute;
  top: 14.2%;
  left: 63%;
  transform: translateX(-50%);
  width: 500px;
  height: 500px;
  object-fit: contain;
`;

const InfoContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: baseline;

`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;

  .heading {
    color: rgba(51, 78, 37, 1);
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    margin:0px;
  }

  .description {
    font-size: 16px;
    font-weight: 400;
    color: rgba(89, 112, 58, 1);
    margin:0px;
  }
`;

const InfoBox2 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;

  .heading {
    color: rgba(51, 78, 37, 1);
    font-size: 11px;
    font-weight: 600;
    text-align: left;
    margin:0px;
  }

  .description {
    font-size: 16px;
    font-weight: 400;
    color: rgba(89, 112, 58, 1);
    margin:0px;
  }
`;

const TestimonialSection = styled.div`
  position: absolute;
  top: 18.3%;
  right: -10.4%;
  transform: translateX(-50%);
  width: 211px;
  height: 270px;
`;

const TestimonialText = styled.div`
  position: absolute;
  top: 20px;
  left: 45%;
  transform: translateX(-50%);
  padding: 0px 25px;
  width: 100%;
  font-size: 12px;
  font-style: italic;
  font-weight: 600;
  line-height: 23px;
  color: #334e25;
  border-radius: 10px;
  text-align: start;

  .userName {
    font-family: Raleway;
    font-size: 15.3px;
    font-style: italic;
    font-weight: 600;
    color: #334e25;
  }
`;

const IconWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
`;

const TestimonialImage = styled.img`
  width: 90%;
  height: 90%;
  border-radius: 20px;
`;
