import React from "react";
import styled from "styled-components";
import {
  HappyCustomerIcon,
  InsurancePartnerIcon,
  TrailedAdvisorIcon,
} from "../../utils/SVG";

const WhyAKInvestment = () => {
  return (
    <Content>
      <Title>Why a.k. Investments</Title>
      <StatsContainer>
        <StatCard>
          <IconWrapper color="#ECD729">
            <TrailedAdvisorIcon />
          </IconWrapper>
          <TextWrapper>
            <StatValue>50+</StatValue>
            <StatLabel>Trailed Advisors</StatLabel>
          </TextWrapper>
        </StatCard>

        <StatCard>
          <IconWrapper color="#7CB518">
            <HappyCustomerIcon />
          </IconWrapper>
          <TextWrapper>
            <StatValue>20K +</StatValue>
            <StatLabel>Happy Customers</StatLabel>
          </TextWrapper>
        </StatCard>

        <StatCard>
          <IconWrapper color="#FB6107">
            <InsurancePartnerIcon />
          </IconWrapper>
          <TextWrapper>
            <StatValue>45+</StatValue>
            <StatLabel>Insurance Partners</StatLabel>
          </TextWrapper>
        </StatCard>
      </StatsContainer>
    </Content>
  );
};

export default WhyAKInvestment;

const Content = styled.div`
  padding: 100px;
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.p`
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  color: #334e25;
  margin-bottom: 40px;
`;

const StatCard = styled.div`
  display: flex;
  gap: 20px;
`;

const IconWrapper = styled.div`
  width: 129px;
  height: 129px;
  background-color: ${(props) => props.color};
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StatValue = styled.p`
  margin: 0;
  color: #334e25;
  font-family: Raleway;
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
`;

const StatLabel = styled.p`
  margin: 0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  color: #59703a;
`;
