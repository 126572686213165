import React from "react";
import styled from "styled-components";
import Logo from "../assets/logo.png";

const HeaderFile = () => {
  return (
    <HeaderWrapper>
      <LogoImage src={Logo} alt="Logo" />
      <NavList>
        <NavItem>Home</NavItem>
        <NavItem>About</NavItem>
        <NavItem>Motor</NavItem>
        <NavItem>Life</NavItem>
        <NavItem>Health</NavItem>
        <NavItem>Miscellaneous</NavItem>
        <NavItem>Achievement</NavItem>
      </NavList>
      <div style={{ display: "flex", gap: "16px" }}>
        <ButtonOutlined>Free Insurance Consultancy</ButtonOutlined>
        <ButtonFilled>Free Doctor Consultancy</ButtonFilled>
      </div>
    </HeaderWrapper>
  );
};

export { HeaderFile };

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogoImage = styled.img`
  width: 197px;
  height: 50px;
`;

const NavList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const NavItem = styled.li`
  margin: 0 16px;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  cursor: pointer;

  &:hover {
    color: #007bff;
  }
            @media (max-width: 840px) {
    display:none;
  }
`;

const ButtonBase = styled.button`
  width: 157px;
  height: 40px;
  border-radius: 100px;
  padding: 7px 12px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
`;

const ButtonOutlined = styled(ButtonBase)`
  color: #688540;
  width: fit-content;
  background: white;
  border: 1px solid #688540;
  font-family: Roboto;
  font-weight: 600;
  font-size: 12px;

  &:hover {
    background: #f4f4f4;
  }

    @media (max-width: 1270px) {
    display:none;
  }
`;

const ButtonFilled = styled(ButtonBase)`
  color: white;
  background: #688540;
  width: fit-content;

  border: none;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 600;
  &:hover {
    background: #557032;
  }
        @media (max-width: 1080px) {
    display:none;
  }
`;
